.section-container {
    transition: all 0.5s ease-in-out;
    position: relative;
    border: none;
    border-radius: 1rem;
    box-shadow: 0px 5px 13px 0px rgba(0, 0, 0, 0.2);
    padding: 2rem;
    margin-top: 3rem;
    background-color: $white;
}

@media (max-width: 768px) {
    .section-container {
        margin-top: 0rem;
        background-color: transparent !important;
        box-shadow: none;
        padding: 0;
    }
}
