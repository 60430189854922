html,
body,
#root {
    height: 100%;
    background: #eee;
}

#root {
    position: relative;
    display: flex;
    flex-direction: column;
}
