@import "../index";

.ReportAmountBox {
    padding: 10px;
    background-color: #fff;
    flex-grow: 1;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;

    &__title {
        font-weight: bold;
    }

    &__amount {
        font-size: 2rem;
        font-weight: bold;
    }
}
