$primary: #00b5ad;

$theme-colors: (
    'primary': #00b5ad,
);

$white: #fff;

$boxShadow: 0px 5px 13px 0px rgba(0, 0, 0, 0.2);

@import '../node_modules/bootstrap/scss/bootstrap';

@import './styles/modal';
@import './styles/react-date-picker';
@import './styles/utils';
@import './styles/table';
@import './styles/section-container';
@import './styles/transaction-item';
@import './styles/category-item';
@import './styles/account-item';
@import './styles/report-tabs';

.table {
    background: #fff;
}
