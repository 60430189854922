.page_header-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 20px;
}

@media (max-width: 768px) {
    .page_header-container {
        background-color: #fff;
        padding: 1rem 15px;
        margin: 0 -15px 1rem;
        box-shadow:  0px 0px 1px 0px rgba(0, 0, 0, 0.2);
    }
}
