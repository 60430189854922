.spinner-container {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: #e3e3e3;
    opacity: .7;
    z-index: 9999;

    .spinner-grow {

    }
}
