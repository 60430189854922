.table {
    font-size: 1rem;

    border: 0;
    th,
    td {
        border-right: 0;
        border-left: 0;
    }

    tr {
        &:hover {
            background-color: #e3e3e3;
        }
    }
}

.table-bordered thead th,
td {
    border-bottom-width: 0;
    border-top: 0;
}
