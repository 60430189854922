.account-item {
    background: #fff;
    margin: 1rem 0;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: $boxShadow;

    .name {
        font-weight: bold;
        margin-bottom: 0;
        font-size: 1.2rem;
    }

    .description {
        font-size: 1rem;
        margin-bottom: 0;
        font-style: italic;
    }

    .value {
        text-align: right;
        font-size: 1.4rem;
        margin-bottom: 0;
    }

    .btn-group {
        margin-top: 10px;
        width: 100%;

        .btn {
            padding-top: 8px;
            padding-bottom: 8px;
        }
    }
}
