.firebase-emulator-warning {
    display: none;
}

.h-4 {
    height: 1.2rem;
}

.w-4 {
    width: 1.2rem;
}

.cursor-pointer {
    cursor: pointer;
}
